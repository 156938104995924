import styled from '@emotion/styled';
import FeaturedSection from '~/components/expert-advice/FeaturedSection';
import NextUpSection from '~/components/expert-advice/NextUpSection';
import H from '@zola/zola-ui/src/typography/Headings';
import P from '@zola/zola-ui/src/typography/Paragraphs';
import COLORS3 from '@zola/zola-ui/src/styles/emotion/colors3';
import FONT from '@zola/zola-ui/src/styles/emotion/fonts';
import MEDIA_QUERY_V2 from '@zola/zola-ui/src/styles/emotion/mediaQueryV2';
import SPACING from '@zola/zola-ui/src/styles/emotion/spacing';
import ContentfulResponsivePicture from '~/components/expert-advice/ContentfulResponsivePicture';

export const PostBody = styled.div`
  h1,
  h2,
  h3,
  h4,
  p,
  ul,
  ol {
    color: ${COLORS3.BLACK_100};
  }
  p,
  ul,
  ol {
    font-family: ${FONT.FONT_FAMILY_CIRCULAR};
    font-size: ${FONT.FONT_SIZE_REGULAR};
    font-weight: ${FONT.FONT_WEIGHT_REGULAR};
  }
`;

export const StyledNextUpSection = styled(NextUpSection)`
  border-bottom: solid 1px ${COLORS3.BLACK_030};
`;

export const StyledFeaturedSection = styled(FeaturedSection)`
  border-bottom: solid 1px ${COLORS3.BLACK_030};
`;

export const BreadcrumbsContainer = styled.div<{
  borderBottom?: boolean;
  borderTop?: boolean;
  onlyMobile?: boolean;
}>`
  padding: ${SPACING.S16} ${SPACING.LG};
  border-bottom: ${({ borderBottom }) => (borderBottom ? `solid 1px ${COLORS3.BLACK_030}` : null)};
  border-top: ${({ borderTop }) => (borderTop ? `solid 1px ${COLORS3.BLACK_030}` : null)};

  ${MEDIA_QUERY_V2.MOBILE} {
    padding: ${SPACING.S16} ${SPACING.S24};
  }

  ${({ onlyMobile }) =>
    onlyMobile === true &&
    `
      ${MEDIA_QUERY_V2.EXCLUDE_MOBILE} {
        display: none;
      }
    `};
`;

export const GridHeader = styled(H.Title3)`
  text-align: center;
  padding: ${SPACING.S24};
  border-bottom: solid 1px ${COLORS3.BLACK_030};
  ${MEDIA_QUERY_V2.MOBILE} {
    ${FONT.textSizeUltraCompact(FONT.SIZE.REGULAR)};
  }
  ${MEDIA_QUERY_V2.DESKTOP} {
    padding: ${SPACING.LG};
  }
`;

export const Grid = styled.div`
  display: grid;
  max-width: 1920px;
  grid-template-columns: 1fr;
  grid-template-rows: max-content;
  grid-gap: 1px;
  grid-auto-flow: row;
  overflow: hidden;
  ${MEDIA_QUERY_V2.DESKTOP} {
    grid-template-columns: 25% 25% 25% 25%;
  }
`;

export const ArticleHeroImgContainer = styled.div`
  display: flex;
  border-radius: ${SPACING.S16};
  overflow: hidden;
  ${MEDIA_QUERY_V2.EXCLUDE_DESKTOP} {
    flex: none;
    height: 75px;
    width: 100px;
  }
  ${MEDIA_QUERY_V2.DESKTOP} {
    height: 168px;
  }
  ${MEDIA_QUERY_V2.DESKTOP_LG} {
    height: 216px;
  }
  ${MEDIA_QUERY_V2.DESKTOP_XL} {
    height: 276px;
  }
  ${MEDIA_QUERY_V2.DESKTOP_MASSIVE} {
    height: 336px;
  }
`;

export const ArticleHeroImg = styled(ContentfulResponsivePicture)`
  height: 100%;
  width: 100%;
  display: flex;
  border-radius: ${SPACING.S16};
  transition: all 0.2s ease-in-out;
  > img {
    object-fit: cover;
    height: auto;
    width: 100%;
  }
`;

export const ArticleContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  ${MEDIA_QUERY_V2.EXCLUDE_DESKTOP} {
    padding-right: ${SPACING.S16};
  }
`;

export const ArticleTopic = styled.span`
  color: ${COLORS3.BLACK_050};
  ${FONT.textSizeCompact(FONT.SIZE.EXTRA_SMALL)}
  ${MEDIA_QUERY_V2.DESKTOP} {
    ${FONT.textSizeCompact(FONT.SIZE.SMALL)}
    margin-top: ${SPACING.S16};
  }
`;

export const ArticleTitle = styled(H.TitleMedium4)`
  margin-top: ${SPACING.XS};
  ${FONT.textSizeCompact(FONT.SIZE.SMALL)}
`;

export const ArticleExerpt = styled(P.BodySmall)`
  margin-top: ${SPACING.XS};
  color: ${COLORS3.BLACK_075};
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  ${MEDIA_QUERY_V2.DESKTOP} {
    ${FONT.textSizeCompact(FONT.SIZE.SMALL)}
  }
`;

export const GridItem = styled.a`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: ${SPACING.S16} ${SPACING.S16} ${SPACING.S32};
  align-self: stretch;
  width: 100%;
  overflow: hidden;
  text-decoration: none;
  box-shadow: 0 0 0 1px ${COLORS3.BLACK_030};
  ${MEDIA_QUERY_V2.EXCLUDE_DESKTOP} {
    flex-direction: row-reverse;
    padding: ${SPACING.S24};
  }
  ${MEDIA_QUERY_V2.TABLET} {
    padding: ${SPACING.S24} ${SPACING.LG};
  }
  @media (hover: hover) {
    :hover {
      text-decoration: none;
      ${ArticleHeroImg} {
        transform: scale(1.1);
      }
      ${ArticleTitle} {
        text-decoration: underline;
      }
    }
  }
`;

export const TotalItemPaginationWrapper = styled.div`
  padding: ${SPACING.S24} 0;
  border-top: 1px solid ${COLORS3.BLACK_030};
  border-bottom: 1px solid ${COLORS3.BLACK_030};
`;
