import React from 'react';
import cx from 'classnames';
import H from '@zola/zola-ui/src/typography/Headings';
import { NextUpPostType } from '~/libs/contentful/types';

import { DESKTOP_MIN } from '~/libs/universal/utils/device';
import Carousel from '~/components/ui/Carousel';
import ArticleThumbnail from '../ArticleThumbnail';

import styles from './ArticleCarouselSection.module.less';

type ArticleCarouselSectionProps = {
  title: string;
  posts: NextUpPostType[];
  className?: string;
};
const ArticleCarouselSection: React.FC<ArticleCarouselSectionProps> = ({
  title,
  posts = [],
  className,
}): JSX.Element => {
  const renderArticles = (mobileView = false) => {
    return posts.map((post, i) => {
      if (!post) return null;
      const { slug, heroImage, tag, title: postTitle, type } = post;
      return (
        <ArticleThumbnail
          key={`up-next-article-${i}`}
          slug={slug}
          heroImage={heroImage}
          tag={tag}
          title={postTitle}
          pageType={type}
          variant={mobileView ? 'horizontal' : 'vertical'}
          hideTag
          className={cx(styles.articleCarousel, {
            [styles.articleCarouselSpacing]: !mobileView,
            [styles.articleMobileSpacing]: mobileView && i < posts.length - 1,
          })}
          breakpointWidthMap={{ 1: 110, [DESKTOP_MIN]: 407 }}
        />
      );
    });
  };
  const renderCarouselContent = () => renderArticles();
  const renderMobile = () => <div className={styles.mobileContainer}>{renderArticles(true)}</div>;

  const responsiveOverride = {
    desktop: {
      breakpoint: { max: 10000, min: 996 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 996, min: 767 },
      items: 3,
    },
  };
  return (
    <div className={cx(styles.container, className)}>
      <H.TitleAccent3>{title}</H.TitleAccent3>
      <Carousel
        className={styles.carousel}
        responsiveOverride={responsiveOverride}
        arrowStyleOverride={styles.arrow}
        rightArrowStyleOverride={styles.rightArrow}
        leftArrowStyleOverride={styles.leftArrow}
        smallerArrows
      >
        {renderCarouselContent()}
      </Carousel>
      {renderMobile()}
    </div>
  );
};

export default ArticleCarouselSection;
