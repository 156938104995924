import React from 'react';
import cx from 'classnames';

import { ImageFieldType } from '~/libs/contentful/types';

import styles from './ArticleThumbnail.module.less';
import ContentfulResponsivePicture from '../ContentfulResponsivePicture';

type ArticleThumbnailVariants = 'horizontal' | 'vertical';

type ArticleThumbnailProps = {
  slug?: string;
  heroImage?: ImageFieldType;
  tag?: string;
  title: string;
  pageType: 'articlePage' | 'checklistPage';
  variant?: ArticleThumbnailVariants;
  className?: string;
  hideTag?: boolean;
  breakpointWidthMap: { [breakpoint: number]: number };
};
const ArticleThumbnail: React.FC<ArticleThumbnailProps> = ({
  slug,
  heroImage,
  tag,
  title,
  className,
  variant = 'horizontal',
  hideTag,
  breakpointWidthMap,
  pageType,
}): JSX.Element | null => {
  const cssPostfix = variant === 'horizontal' ? 'Horizontal' : 'Vertical';
  const classes = cx(
    className,
    styles.container,
    styles[`container${cssPostfix}` as keyof typeof styles]
  );
  const path = pageType === 'articlePage' ? slug : `checklist/${slug}`;

  if (!slug) {
    return null;
  }
  return (
    <a href={`/expert-advice/${path}`} className={classes}>
      {heroImage?.src && (
        <ContentfulResponsivePicture
          src={heroImage?.src}
          alt={heroImage?.altText}
          breakpointWidthMap={breakpointWidthMap}
          aspectRatio={1080 / 720}
        />
      )}
      <div className={styles[`textContainer${cssPostfix}` as keyof typeof styles]}>
        {!hideTag && tag && (
          <div className={styles[`tag${cssPostfix}` as keyof typeof styles]}>{tag}</div>
        )}
        <h3
          className={cx(
            styles[`title${cssPostfix}` as keyof typeof styles],
            'articleThumbnailTitle'
          )}
        >
          {title}
        </h3>
      </div>
    </a>
  );
};

export default ArticleThumbnail;
